import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import * as CONST from "../../../components/const";
// import bg from "../../../images/employment/young/bg.png";
//import headerLeft from "../../../images/employment/young/header_left.png";
//import headerRight from "../../../images/employment/young/header_right.png";
const kv = "../../../images/employment/young/kv.jpg";
const img1 = "../../../images/employment/young/img1.png";
const img2 = "../../../images/employment/young/img2.png";
const img3 = "../../../images/employment/young/img3.png";
const footerLeft = "../../../images/employment/young/footer_left.jpg";
const footerRight = "../../../images/employment/young/footer_right.jpg";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		<Layout>
			<SEO
				description="ALPHA WAVEの若手社員からのメッセージです。是非ともご覧ください。"
				title="Young"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Employment information <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>採用情報</span></p>
					</div>
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<h2 className={CONST.H2_CLASS}>先輩社員からのメッセージ</h2>
							<h2 className={CONST.H2_CLASS}>池谷　ハナコ</h2>
							<p className={CONST.H5_CLASS}>(2011年4月入社)英文学科卒</p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={kv} />
					<div className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS}>
						<div>
							<p>前向きな気持ちがあれば、</p>
							<p>入社後は学生時代よりも充実すると思います！</p>
						</div>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<p>こんにちは、池谷と申します。私は当社に2011年に入社しました。</p>
						<p>これから社会人になる皆さんは、企業で働くことに色々な心配を抱いているかもしれませんね？私も学生当時は「枠にはまってつまらないことが多くなるのでは…」と不安に思っていました。</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>しかし、そのネガティブな気持ちは当社に入社して一掃されました。そんな私の今までの経験談をこれからお伝えします。皆さんの不安が少しでも解消されたら幸いです。</p>
						<p className={CONST.H3_CLASS + CONST.LINE_SPACING_PB_CLASS}>想像以上に「会話による意思統一」が大切な仕事です</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={img1} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-12/12 lg:w-3/12"></div>
				<div className="w-12/12 lg:w-9/12">
					<p>就職活動でシステム開発会社を調べると、ＳＥはコミュニケーションが大切な仕事であると聞くと思います。私もそのことを知った上で入社しましたが、実際に働くと「想像以上に会話や意思統一が重要な仕事だ！」と感じました。というのも、日々の仕事の割合が、パソコンに向かい実装作業等を行うことが5割、チーム内の打ち合わせが3割、お客様とのミーティングが2割位と、予想以上に打ち合わせが多かったからです。</p>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={img2} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<p>私のチームでは毎朝デイリーミーティングを30分程行います。これはチームで取り組んでいる「アジャイル開発」において重要な“チーム内の意思統一”のためで、進捗状況と本日の作業予定の報告や、テスト作業の結果確認をすると同時に、作業における問題点の共有・相談も行っています。他にも、作業をよりスムーズに進めるために実装前の打ち合わせも大切していて、作り始めてから「ここはどうするんだっけ？」と分からなくなる人がいたり、間違って仕様と違う方向に作業を進めてしまう人がいたりすることが無いように、時には夜遅くまでじっくりと打ち合わせをすることもあります。実際に作り始めてからも頻繁に情報共有をしています。</p>
						<p>私自身、人と接することに積極的であると思っているため(笑)、周囲と進んで会話すべき職に就くことが出来て、日々の仕事がおもしろいと感じています。更に、これは当社の風通しの良さの表れでありますが、若手社員の意見も良いものであれば積極的に取り入れてもらえるため、気づいたことはどんどん発信していこうと思えてやる気が湧いてきます。</p>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-12/12 lg:w-3/12"></div>
				<div className="w-12/12 lg:w-9/12">
					<p className={CONST.H3_CLASS + CONST.LINE_SPACING_PB_CLASS}>「知らないということに怯えてはいけないよ」と先輩に教わり、発想を転換しました</p>
					<p>仕事は楽しいですが、勿論大変なこともあります。一年目は分からないことが多く、先輩達の話のスピードについていくのが難しいという壁にぶつかっていました。その時、現場のベテラン上司が「知らないということに怯えてはいけないよ」とアドバイスをしてくれました。「技術者として十年以上働いてきた今でも、これまで培ってきた知識だけで完成出来るシステムはほぼ無いし、私達の仕事は常に新しいことを学び続けていく仕事なんだよ」と教えてもらったことで、発想が前向きになれたのです。知らない・出来ないからダメだ…と落ち込んでしまうのではなく、勉強して出来るようになっていけば良いし、そうなればもっと仕事がおもしろくなる！と考えるようになりました。分からないことが多過ぎて叱られることもありますが、先輩に積極的に質問をしたり、自宅でも調べたりすることを続けて解決していきました。こうして自分が作ったシステムが正しく動くところを見て、達成感を得ることが出来ています。</p>
					<p>当社は部下や後輩思いな人が沢山います。二年目の終わりに初めて自分一人で一つのサブシステムを作る仕事を任せていただいた時、不安よりも嬉しい気持ちの方がずっと大きかったのですが、それは、チームにフォローしてくれる先輩がいるから安心して挑戦できると思えたからです。そしてそのフォローが、こちらが甘えても大丈夫なように代わりにやってくれるのではなく、自分のやる気を掻き立ててくれるものであるからとても有難く思っています。「これを調べてごらん」「これを使ってみたら？」という助言であったり、使わなくなった参考書を譲って下さったりと、その後の自分の取り組み次第で成長に繋がる上、仕事が面白くなる手助けにも繋がっているからです。</p>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<p className={CONST.H3_CLASS + CONST.LINE_SPACING_PB_CLASS}>毎日が充実しているのは、仕事の責任を得ることが出来たから</p>
						<p>業務外の話ですが、私は先輩達と遊んだり飲みに行ったりすることがよくあります。</p>
						<p>元々私が人の集まる場に顔を出すことが好きなこともありますが、当社は後輩が仲間に加わることを歓迎してくれる先輩が多いおかげで、私の人間関係の輪が広がったのだと思っています。先輩が企画してくれた小旅行に一緒に行ったり、サッカー部やバレー部、ヨット部等といった部活動にも参加したりすることで、会社内に業務の異なる知り合いの先輩が増えました。そして私が仕事で困っていることがあれば、気軽に飲みながら真剣に、時に厳しくアドバイスをしてくれるので、「明日から頑張ろう」と気持ちを切り替えることが出来ます。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={img3} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-12/12 lg:w-9/12">
					<p>私は、今がとても楽しいです。</p>
					<p className={CONST.LINE_SPACING_PB_CLASS}>その理由の一つは「年齢の異なる人とのコミュニケーションの輪が広がったこと」。もう一つは、学生時代と違い「自分の仕事の対価としてお給料をもらう責任がある中で生活をするようになったこと」です。入社前は大変そうだと思っていましたが、いざ飛び込んでみると、気持ちにハリが出て毎日がより有意義なものになりました。最近では私にも後輩がついたので、一層責任を感じています。更に今後、まずは小さい案件のリーダーになって、先輩達の仕事の負担を軽減するという目標を持つことも出来ました。主体的に取り組んでいれば、そのチャンスは必ず巡ってくると思っています。</p>
					<p>皆さんも前向きな気持ちや積極性さえあれば、大変な仕事も楽しくなりますし、何でも相談出来る様な先輩を見つけられると思います。当社にご縁があって入社した際は、ぜひ変に壁を作らず、先輩達と素直な気持ちでコミュニケーションをとって下さい。余計な遠慮は要らないと思いますよ！</p>
				</div>
				<div className="w-12/12 lg:w-3/12"></div>
			</section>

			<section className={CONST.SECTION_PB_AREA_CLASS}>
				<div className="w-full md:w-1/12 lg:w-3/12"></div>
				<div className="w-full md:w-5/12 lg:w-3/12 p-5">
					<Link key='senior' to='../senior/'>
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={footerLeft} />
					</Link>
				</div>
				<div className="w-full md:w-5/12 lg:w-3/12 p-5">
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={footerRight} />
				</div>
				<div className="w-full md:w-1/12 lg:w-3/12"></div>
			</section>
		</Layout>
	);
}

export default IndexPage;
